import React from "react"

import { withAccountActivate } from "./withAccountActivate"
import { Breadcrumbs } from "../../Breadcrumbs/Breadcrumbs"
import { AccountActivateForm } from "./Form/AccountActivateForm"
import { StyledContainer } from "../../Styled/Container"
import { Page, Title } from "./styledAccountActivate"

export const AccountActivate = withAccountActivate(
  ({ location, page }): JSX.Element => (
    <Page>
      <Breadcrumbs background={`orange-pastel`} colour={`dark`} page={page} />
      <StyledContainer width={`md`}>
        <Title as={`h1`} align={`center`} withSpacing>
          {page?.title}
        </Title>
        <AccountActivateForm location={location} page={page} />
      </StyledContainer>
    </Page>
  )
)
